<template>
    <div class="EmailCampaignContentView ViewWrapper">
        <clv-head-meta :title="$t('Email Campaign Content and Design')"></clv-head-meta>

        <!-- TRANSPARENT LOCK -->
        <clv-interaction-locker :active="emailCampaignUpdating"></clv-interaction-locker>

        <!-- STATE INDICATORS / LOADERS -->
        <element-loading :active="emailCampaignGetting" :is-full-view="true" :lock-parent="true"></element-loading>

        <!-- EMPTY STATE 2020-07-19 -->
        <simple-state-not-found :for-view="true" v-if="emailCampaignNotFound">
            <div class="mt-2" slot="action">
                <router-link tag="a" :to="{ name: 'campaigns' }"><i class="fas fa-chevron-left"></i> {{ $t('Browse Campaigns') }}</router-link>
            </div>
        </simple-state-not-found>

        <div v-if="contextDeviceHasSmallScreen" key="small-screen">
            <email-campaign-content-small-device-preview v-if="emailCampaign"></email-campaign-content-small-device-preview>
        </div>
        <div v-else key="medium-and-above-screen">
            <email-campaign-content-header v-if="emailCampaign"
                                           :key="`header-${activeComponentCommonKey}`"></email-campaign-content-header>
            <email-campaign-content-edit v-if="emailCampaign"
                                         :key="`edit-${activeComponentCommonKey}`"></email-campaign-content-edit>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RouterUtils from '@/common/utils/router.utils';
import EmailCampaignContentSmallDevicePreview from './EmailCampaignContentSmallDevicePreview';
import EmailCampaignContentHeader from './EmailCampaignContentHeader';
import EmailCampaignContentEdit from './EmailCampaignContentEdit';

/**
 * EmailCampaign content and design.
 *
 * If the EmailCampaign is not draft,
 * user will be redirected in preview sub-view in EmailCampaignView.
 *
 * @author Dimitris Gkoulis
 * @createdAt 7 November 2020
 */
export default {
    name: 'EmailCampaignContentView',
    components: {
        EmailCampaignContentSmallDevicePreview,
        EmailCampaignContentHeader,
        EmailCampaignContentEdit
    },
    computed: {
        ...mapGetters('emailCampaignContent', {
            emailCampaignGetting: 'emailCampaignGetting',
            emailCampaignUpdating: 'emailCampaignUpdating',
            emailCampaignNotFound: 'emailCampaignNotFound',
            emailCampaign: 'emailCampaign',
            activeComponentCommonKey: 'activeComponentCommonKey'
        }),
        ...mapGetters('application', {
            contextDeviceHasSmallScreen: 'contextDeviceHasSmallScreen'
        })
    },
    watch: {
        '$route': {
            handler: function (to, from) {
                const result = RouterUtils.resourceIdHelper(to, from);
                const id = result.toId;

                if (result.mustInitialize) {
                    this.$store.commit('application/navigationContainerSwitchToFluid');
                    this.$store.dispatch('emailCampaignContent/initializeModule', id)
                        .then((emailCampaign) => {
                            if (emailCampaign.status !== 'DRAFT') {
                                this.$router.push({
                                    name: 'email-campaign',
                                    params: {
                                        id: emailCampaign.id
                                    },
                                    query: {
                                        view: 'preview'
                                    }
                                });
                            }
                        })
                        .catch(() => void 0);
                    // No need to commit 'updateActiveComponentCommonKey'
                    // because this operation will force the view to re-mount.
                }
            },
            immediate: true
        }
    },
    beforeMount () {
        this.$store.commit('emailCampaignContent/updateActiveComponentCommonKey');
    },
    beforeDestroy () {
        this.$store.commit('application/navigationContainerSwitchToDefault');
        this.$store.dispatch('emailCampaignContent/resetModule').then(() => void 0).catch(() => void 0);
    }
};
</script>
