<template>
    <off-canvas-panel :display-panel="open"
                      :panel-width="panelWidth"
                      :with-box-shadow-left="true"
                      :with-overlay="true">
        <!-- PANEL HEADER -->
        <div class="p-3" slot="header">
            <p class="mb-0 weight-5 size-6">{{ $t('Email Template') }}</p>
        </div>

        <!-- PANEL BODY -->
        <div slot="body">
            <!-- PREVIEW A SINGLE EMAIL TEMPLATE -->
            <div class="preview-section position-relative"
                 v-if="previewItem"
                 key="preview">
                <div class="border-bottom px-4 py-3 bg-light">
                    <div class="d-flex align-items-center">
                        <p class="mb-0 mr-1">{{ previewItem.name }}</p>
                        <button class="btn btn-sm btn-secondary-alt"
                                :disabled="disableChooseButton(previewItem)"
                                @click="choose(previewItem)">{{ $t('Choose') }}</button>
                        <button class="btn btn-sm btn-secondary-alt ml-auto"
                                @click="previewItem = null">
                            <i class="fas fa-chevron-left"></i> {{ $t('common_action_return') }}
                        </button>
                    </div>
                </div>
                <clv-iframe-sync :html="previewItem.html"></clv-iframe-sync>
            </div>

            <!-- EMAIL TEMPLATES SEARCH AND LISTING -->
            <div class="search-section position-relative"
                 v-else
                 key="listing">
                <div class="border-bottom px-4 py-3 bg-light">
                    <input class="fluid-shadowed-input-1"
                           type="text"
                           name="query"
                           id="query"
                           :placeholder="$t('Type to search')"
                           v-model="query" />
                </div>
                <div>
                    <div class="text-center my-4" v-if="results.length === 0">
                        <p>{{ $t('no results') }}</p>
                    </div>
                    <div class="border-bottom px-4 py-3" v-for="resultItem in results" :key="resultItem.id">
                        <div class="mb-2">
                            <p class="mb-0 text-microcopy--60 font-weight-bold">{{ $t(resultItem.editor) }}</p>
                            <p class="mb-0">{{ resultItem.name }}</p>
                        </div>
                        <div>
                            <button class="btn btn-sm btn-secondary-alt mr-1"
                                    @click="choose(resultItem)"
                                    :disabled="disableChooseButton(resultItem)">{{ $t('Choose') }}</button>
                            <button class="btn btn-sm btn-secondary-alt"
                                    @click="preview(resultItem)">{{ $t('Preview') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- PANEL FOOTER -->
        <div slot="footer">
            <div class="p-3">
                <button class="btn btn-sm btn-secondary-alt"
                        @click="close">{{ $t('Close') }}</button>
            </div>
        </div>
    </off-canvas-panel>
</template>

<script>
import debounce from 'lodash/debounce';
import { EmailTemplateService } from '@/common/services/api.service';

const DEFAULT_DEBOUNCE_WAIT = 500;

/**
 * EmailTemplate chooser.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 5 November 2020
 * @lastModifiedAt 8 November 2020 (bug fix)
 */
export default {
    name: 'EmailTemplateChooser',
    props: {
        open: {
            type: Boolean,
            default: false,
            required: false
        },
        editor: {
            type: String,
            default: null, // null implies that all types can be selected.
            required: false,
            validator: function (value) {
                return [null, 'BUILDER', 'HTML'].indexOf(value) !== -1;
            }
        },
        excludeIds: {
            type: Array,
            default: function () {
                return [];
            },
            required: false
        }
    },
    data () {
        return {
            loading: false,
            query: '',
            results: [],
            previewItem: null,
            error: null
        };
    },
    computed: {
        panelWidth () {
            return this.previewItem === null ? '20vw' : '50vw';
        }
    },
    mounted () {
        this.$watch('query', {
            handler: debounce(function (value) {
                this.searchEmailTemplates(value);
            }, DEFAULT_DEBOUNCE_WAIT),
            immediate: true
        });
    },
    methods: {
        /**
         * Searches EmailTemplates instances by name.
         */
        searchEmailTemplates (term) {
            // Start loader.
            this.loading = true;

            // Search emailTemplates by name.
            EmailTemplateService.getAllEmailTemplatesByName(term)
                .then(({ data }) => {
                    this.results = data;
                    this.error = null;
                })
                .catch((reason) => {
                    this.results = [];
                    this.error = reason;
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        /**
         * Sends "choose" signal to parent.
         */
        choose (emailTemplate) {
            this.resetAll();
            this.$emit('choose', emailTemplate);
        },

        /**
         * Sends "close" signal to parent component.
         */
        close () {
            // Do not reset anything.
            // Use can open the chooser later.
            this.$emit('close');
        },

        /**
         * Sets an EmailTemplate for preview.
         */
        preview (emailTemplate) {
            this.previewItem = emailTemplate;
        },

        /**
         * Resets all data and performs a search in order to restore the initial results.
         */
        resetAll () {
            this.loading = false;
            this.query = '';
            this.results = [];
            this.previewItem = null;
            this.error = null;

            // And search again in case user opens chooser in later time.
            this.searchEmailTemplates(this.query);
        },

        /**
         * Returns true if a specific EmailTemplate cannot be chose because of its type.
         * It used to disable the corresponding button in EmailTemplate listing.
         */
        disableChooseButton (emailTemplate) {
            if (typeof this.editor !== 'string') return false;
            if (emailTemplate === null) return true;
            return this.editor !== emailTemplate.editor;
        }
    }
};
</script>

<i18n>
{
    "en": {
        "BUILDER": "BUILDER",
        "HTML": "HTML",
        "Email Template": "Email Template",
        "Close": "Close",
        "Choose": "Choose",
        "Preview": "Preview",
        "Type to search": "Type to search",
        "no results": "No result found"
    },
    "el": {
        "BUILDER": "ΧΤΙΣΤΗΣ",
        "HTML": "HTML",
        "Email Template": "Πρότυπο Email",
        "Close": "Κλείσιμο",
        "Choose": "Επιλογή",
        "Preview": "Προεπισκόπηση",
        "Type to search": "Πληκτρολογήστε για αναζήτηση"
    }
}
</i18n>
