<template>
    <div class="EmailCampaignContentEdit" v-if="siInitialized">
        <div v-if="designEditor === 'BUILDER'" key="BUILDER">
            <email-template-builder :builder-data-initial="builderData"
                                    v-on:build-success-initial="handleEmailTemplateBuilderSignalForBuildSuccess"
                                    v-on:build-success="handleEmailTemplateBuilderSignalForBuildSuccess"
                                    v-on:build-fail-initial="handleEmailTemplateBuilderSignalForBuildFail"
                                    v-on:build-fail="handleEmailTemplateBuilderSignalForBuildFail"></email-template-builder>
        </div>
        <div v-else-if="designEditor === 'HTML'" key="HTML">
            <email-template-code-editor :html-initial="html"
                                        v-on:build-success-initial="handleEmailTemplateCodeEditorSignalForBuildSuccess"
                                        v-on:build-success="handleEmailTemplateCodeEditorSignalForBuildSuccess"
                                        v-on:build-fail-initial="handleEmailTemplateCodeEditorSignalForBuildFail"
                                        v-on:build-fail="handleEmailTemplateCodeEditorSignalForBuildFail"></email-template-code-editor>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LoggingService from '@/common/services/logging.service';
import EmailTemplateCodeEditor from '@/modules/EmailTemplateCodeEditor/views/EmailTemplateCodeEditor';
import EmailTemplateBuilder from '@/modules/EmailTemplateBuilder/views/EmailTemplateBuilder';

/**
 * EmailCampaign content & design editor.
 *
 * @author Dimitris Gkoulis
 * @createdAt 7 November 2020
 */
export default {
    name: 'EmailCampaignContentEdit',
    components: {
        EmailTemplateBuilder,
        EmailTemplateCodeEditor
    },
    computed: {
        ...mapGetters('emailCampaignContent', {
            emailCampaign: 'emailCampaign'
        })
    },
    data () {
        return {
            /**
             * A flag that declares if this component can be displayed.
             */
            siInitialized: false,

            /**
             * Content editor to display.
             */
            designEditor: 'NONE',

            /**
             * This object is the BuilderData Object.
             * Used by EmailTemplateBuilder module.
             *
             * The instance in DB has the serialized version of this object.
             * We have to parse and convert to a JSON Object.
             */
            builderData: null,

            /**
             * HTML code.
             * Used by EmailTemplateCodeEditor module.
             */
            html: null
        };
    },
    beforeMount () {
        this.siInitialized = false;
        this.designEditor = 'NONE';
        this.builderData = null;
        this.html = null;

        const emailCampaignEditor = this.emailCampaign.editor;
        const emailCampaignHtml = this.emailCampaign.bodyHtml;
        const emailCampaignBuilderDataSerialized = this.emailCampaign.builderDataSerialized;

        try {
            this.builderData = JSON.parse(emailCampaignBuilderDataSerialized);
        } catch (e) {
            this.builderData = null; // We expect that builder will creat a new template.
            LoggingService.warn('Failed to parse builderData');
            LoggingService.warn(e);
            this.toastError('Builder Data parsing failed!');
        }

        if (typeof emailCampaignHtml === 'string') {
            // It's highly unexpected for html to be non-string.
            this.html = emailCampaignHtml;
        } else {
            this.html = '';
        }

        this.designEditor = emailCampaignEditor;

        this.siInitialized = true;
    },
    methods: {
        /**
         * Handles EmailTemplateBuilder's signal for 'build-success' or 'build-success-initial'.
         */
        handleEmailTemplateBuilderSignalForBuildSuccess ($event) {
            this.setBuilderDataSerializedToEmailCampaign($event.builderData);
            this.setBodyHtmlToEmailCampaign($event.html);
        },

        /**
         * Handles EmailTemplateBuilder's signal for 'build-fail' or 'build-fail-initial'.
         */
        handleEmailTemplateBuilderSignalForBuildFail ($event) {}, // NOT YET SUPPORTED!

        /**
         * Handles EmailTemplateCodeEditor's signal for 'build-success' or 'build-success-initial'.
         */
        handleEmailTemplateCodeEditorSignalForBuildSuccess ($event) {
            this.setBuilderDataSerializedToEmailCampaign(null);
            this.setBodyHtmlToEmailCampaign($event.html);
        },

        /**
         * Handles EmailTemplateCodeEditor's signal for 'build-fail' or 'build-fail-initial'.
         */
        handleEmailTemplateCodeEditorSignalForBuildFail ($event) {}, // NOT YET SUPPORTED!

        /**
         * Set 'builderDataSerialized' value to EmailCampaign.
         */
        setBuilderDataSerializedToEmailCampaign (data) {
            let builderDataSerialized;
            try {
                builderDataSerialized = JSON.stringify(data);
            } catch (e) {
                builderDataSerialized = '';
                LoggingService.warn('Failed to stringify builderData');
                LoggingService.warn(e);
                this.toastError('Builder Data serialization failed!');
            }

            this.$store.commit('emailCampaignContent/setEmailCampaignField', {
                field: 'builderDataSerialized',
                value: builderDataSerialized
            });
        },

        /**
         * Set 'bodyHtml' value to EmailCampaign.
         */
        setBodyHtmlToEmailCampaign (data) {
            this.$store.commit('emailCampaignContent/setEmailCampaignField', {
                field: 'bodyHtml',
                value: data
            });
        }
    }
};
</script>

<style lang="scss">
    .EmailCampaignContentEdit {

        .UI-Fullscreen-Column {

            &.for-view {
                min-height: calc(100vh - 60px - 91px);
                max-height: calc(100vh - 60px - 91px);
            }
        }
    }
</style>
