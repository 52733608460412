<template>
    <div class="EmailCampaignContentHeader bg-white border-bottom">
        <div class="container-fluid">
            <div class="d-flex justify-content-between align-items-center py-3">
                <div>
                    <p class="mb-0 text-microcopy--60 font-weight-bold text-uppercase">{{ $t('Email Campaign') }}</p>
                    <p class="size-5 weight-7 line-height-2 mb-0"><span class="text-muted"></span> {{ emailCampaign.name | ellipsis(64) }} <span class="text-muted"> / {{ $t('Content & Design') }}</span></p>
                    <p class="mb-0 mt-1 text-microcopy--70 text-muted">
                        <ring size="14" :color="'#1070ca'" v-if="emailCampaignUpdating"></ring> {{ $t('Edited on') }} {{ emailCampaign.meta.lastModifiedDate | dateLiteral }} {{ $t('by') }} {{ emailCampaign.meta.lastModifiedBy }}
                    </p>
                </div>
                <div>
                    <div class="btn-toolbar" role="toolbar" aria-label="Toolbar">
                        <div class="btn-group mr-2" role="group" aria-label="Content and Design actions">
                            <button class="btn btn-light"
                                    :title="$t('Switch editor')"
                                    @click="confirmAndProceedToAction('switch')">
                                <i class="fas fa-exchange-alt"></i>
                            </button>
                            <button class="btn btn-light"
                                    :title="$t('Import design by Email Template')"
                                    @click="openEmailTemplateChooser">
                                <i class="fas fa-file-import"></i>
                            </button>
                            <button class="btn btn-light"
                                    :title="$t('Discard changes')"
                                    @click="confirmAndProceedToAction('discard')">
                                <i class="fas fa-undo-alt"></i>
                            </button>
                            <button class="btn btn-light"
                                    :title="$t('common_action_save')"
                                    @click="updateEmailCampaign">
                                <i class="fas fa-save"></i>
                            </button>
                        </div>
                        <div class="btn-group" role="group" aria-label="Email Campaign actions">
                            <button class="btn btn-primary"
                                    :title="$t('Save and continue to Email Campaign')"
                                    @click="updateAndGoToEmailCampaign">{{ $t('common_action_continue') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <email-template-chooser :open="emailTemplateChooserOpen"
                                :editor="emailCampaign.editor"
                                v-on:close="closeEmailTemplateChooser"
                                v-on:choose="handleEmailTemplateChooserSignalForChoose"></email-template-chooser>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Ring from '@/components/loading/Loaders/Ring';
import EmailTemplateChooser from '@/modules/EmailTemplateChooser/views/EmailTemplateChooser';

/**
 * Header for EmailCampaignContent view.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 7 November 2020
 */
export default {
    name: 'EmailCampaignContentHeader',
    components: {
        Ring,
        EmailTemplateChooser
    },
    data () {
        return {
            emailTemplateChooserOpen: false
        };
    },
    computed: {
        ...mapGetters('emailCampaignContent', {
            emailCampaign: 'emailCampaign',
            emailCampaignUpdating: 'emailCampaignUpdating'
        })
    },
    methods: {
        /**
         * Updates the EmailCampaign and redirects to the EmailCampaignView (EmailCampaign management view).
         */
        updateAndGoToEmailCampaign () {
            this.$store.dispatch('emailCampaignContent/updateEmailCampaign')
                .then(() => {
                    this.toastDefault(this.$t('Email Campaign Content & Design updated'), false);
                    this.$router.push({ name: 'email-campaign', params: { id: this.emailCampaign.id } });
                })
                .catch((reason) => {
                    this.toastErrorFromError(reason);
                });
        },

        /**
         * Updates the EmailCampaign.
         */
        updateEmailCampaign () {
            this.$store.dispatch('emailCampaignContent/updateEmailCampaign')
                .then(() => {
                    this.toastDefault(this.$t('Email Campaign Content & Design updated'), false);
                })
                .catch((reason) => {
                    this.toastErrorFromError(reason);
                });
        },

        /**
         * Display confirmation message box.
         * If user selects to proceed, it proceeds to the corresponding logic.
         *
         * @param action the action to confirm and proceed to.
         */
        confirmAndProceedToAction (action) {
            if (typeof action !== 'string') return;

            let originalTitle = 'Confirm Action';
            let originalMessage = '';
            if (action === 'switch') {
                if (this.emailCampaign.editor === 'BUILDER') {
                    originalTitle = 'Switch to HTML code editor?';
                    originalMessage = 'Content and design will be reset.';
                } else if (this.emailCampaign.editor === 'HTML') {
                    originalTitle = 'Switch to email builder?';
                    originalMessage = 'Content and design will be reset.';
                }
            } else if (action === 'discard') {
                originalTitle = 'Discard changes?';
                originalMessage = 'All changes will be discarded.';
            } else {
                return;
            }

            const messageBoxConfiguration = {
                title: this.$t(originalTitle),
                message: this.$t(originalMessage),
                showCancelButton: true,
                confirmButtonText: this.$t('common_action_okay'),
                cancelButtonText: this.$t('common_action_cancel'),
                closeOnClickModal: false,
                closeOnPressEscape: true,
                roundButton: false,
                showClose: false,
                customClass: 'el-message-box--w400',
                lockScroll: false
            };

            this.$msgbox(messageBoxConfiguration)
                .then((response) => {
                    if (response !== 'confirm') return;

                    if (action === 'switch') {
                        this.$store.dispatch('emailCampaignContent/resetEmailCampaignContentAndSwitchEditor')
                            .then(() => {
                                this.$store.commit('emailCampaignContent/updateActiveComponentCommonKey');
                            })
                            .catch(() => void 0);
                    } else if (action === 'discard') {
                        this.$store.dispatch('emailCampaignContent/discardEmailCampaignContentChanges')
                            .then(() => {
                                this.$store.commit('emailCampaignContent/updateActiveComponentCommonKey');
                            })
                            .catch(() => void 0);
                    } else {} // Theoretically unreachable.
                })
                .catch(() => void 0);
        },

        /**
         * Handles EmailTemplateChooser's signal for "choose".
         *
         * @param $event the EmailTemplate instance
         */
        handleEmailTemplateChooserSignalForChoose ($event) {
            this.closeEmailTemplateChooser();

            // noinspection UnnecessaryLocalVariableJS
            const emailTemplate = $event;
            if (this.emailCampaign.editor !== emailTemplate.editor) {
                return;
            }

            if (this.emailCampaign.editor === 'HTML') {
                this.$store.commit('emailCampaignContent/setEmailCampaignField', {
                    field: 'builderDataSerialized',
                    value: null
                });
                this.$store.commit('emailCampaignContent/setEmailCampaignField', {
                    field: 'bodyHtml',
                    value: emailTemplate.html
                });

                this.$store.commit('emailCampaignContent/updateActiveComponentCommonKey');
            } else if (this.emailCampaign.editor === 'BUILDER') {
                this.$store.commit('emailCampaignContent/setEmailCampaignField', {
                    field: 'builderDataSerialized',
                    value: emailTemplate.builderDataSerialized
                });
                this.$store.commit('emailCampaignContent/setEmailCampaignField', {
                    field: 'bodyHtml',
                    value: ''
                });

                this.$store.commit('emailCampaignContent/updateActiveComponentCommonKey');
            } else {} // Theoretically unreachable.
        },

        /**
         * Opens the EmailTemplate chooser (off-canvas panel).
         */
        openEmailTemplateChooser () {
            this.emailTemplateChooserOpen = true;
        },

        /**
         * Closes the EmailTemplate chooser (off-canvas panel).
         */
        closeEmailTemplateChooser () {
            this.emailTemplateChooserOpen = false;
        }
    }
};
</script>
