<template>
    <div class="EmailCampaignContentSmallDevicePreview">
        <!-- HEADER -->
        <div class="bg-white border-bottom mb-4">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="py-3 px-0">
                            <router-link tag="p"
                                         class="mb-1 text-microcopy--70 cursor-pointer text-primary"
                                         :to="{ name: 'email-campaign', params: { id: emailCampaign.id } }">
                                <i class="fas fa-chevron-left"></i> {{ $t('Back to Email Campaign') }}
                            </router-link>
                            <p class="mb-0 weight-7 size-5">{{ emailCampaign.name }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- NOTIFICATION ABOUT EDIT -->
        <div class="container mb-4">
            <div class="alert alert-info C-Alert text-center mb-0">
                <p class="mb-0">{{ $t('email_campaign_content_desktop_edit_1') }}</p>
            </div>
        </div>

        <!-- PREVIEW -->
        <div class="container mb-4">
            <clv-iframe-sync :html="emailCampaign.bodyHtml"></clv-iframe-sync>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

/**
 * Small device view.
 * Displays a preview of the email. All actions are disabled.
 *
 * @author Dimitris Gkoulis
 * @createdAt 7 November 2020
 */
export default {
    name: 'EmailCampaignContentSmallDevicePreview',
    computed: {
        ...mapGetters('emailCampaignContent', {
            emailCampaign: 'emailCampaign'
        })
    }
};
</script>
